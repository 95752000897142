import { type ActionFunctionArgs, json, type LoaderFunctionArgs, type MetaFunction, redirect } from '@remix-run/node'
import {Form, Link, useActionData, useNavigate, useSearchParams, useLocation} from '@remix-run/react'
import React, { useEffect, useState } from 'react'
import { useIsPending } from '#app/utils/misc.tsx'
import { AuthenticityTokenInput } from 'remix-utils/csrf/react'
import { HoneypotInputs } from 'remix-utils/honeypot/react'
import _ from 'lodash'
import { getFormProps, getInputProps, useForm } from '@conform-to/react'
import { z } from 'zod'
import { EmailSchema, PasswordSchema } from '#app/utils/user-validation.ts'
import { ErrorBulletList, Field, PasswordField } from '#app/components/forms.tsx'
import { validateCSRF } from '#app/utils/csrf.server.ts'
import { checkHoneypot } from '#app/utils/honeypot.server.ts'
import { StatusButton } from '#app/components/ui/status-button.tsx'
import { post } from '#app/utils/refundr.server.aragorn.ts'
import { getZodConstraint, parseWithZod } from '@conform-to/zod'
import { type LoginResponse } from '#app/model/new-responses.ts'
import { sessionStorage, requireAnonymous, sessionAuthProviderKey, sessionKey } from '#app/utils/session.server.ts'
import { safeRedirect } from 'remix-utils/safe-redirect'
import Notification from '#app/components/notification.tsx';

export const meta: MetaFunction = () => [{ title: 'Login | refundr' }]

const LoginFormSchema = z.object({
	username: EmailSchema,
	password: PasswordSchema,
	redirectTo: z.string().optional(),
})

export async function action({ request }: ActionFunctionArgs) {
	// Get user id from session if it exists else redirect to home page
	await requireAnonymous(request)
	const formData = await request.formData()
	// csrf stand for Cross-Site Request Forgery. This involves saving and verifying a token saved in browser cookie
	await validateCSRF(formData, request.headers)
	// Honeypot is a simple technique to prevent spam bots from submitting forms. It works by adding a hidden field to the form that bots will fill, but humans won't.
	checkHoneypot(formData)

	// LOGIN START
	const submission = await parseWithZod(formData, {
		schema: intent =>
			LoginFormSchema.transform(async (data, ctx) => {
				if (intent !== null) return { ...data, response: null }

				const response = await post<{ email: string; password: string }, LoginResponse>(
					request,
					'/aws/login/',
					{ email: data.username, password: data.password }, true
				)
				if (!response.success && 400 === response.statusCode || !response.success && 409 === response.statusCode) {
					ctx.addIssue({
						code: z.ZodIssueCode.custom,
						message: 'Invalid username or password',
					})
					return z.NEVER
				}
				return { ...data, response: response.data as LoginResponse }
			}),
		async: true,
	})
	// LOGIN END

	if (submission.status !== 'success' || !submission.value.response) {
		return json(
			{ result: submission.reply({ hideFields: ['password'] }) },
			{ status: submission.status === 'error' ? 400 : 200 },
		)
	}

	const { response } = submission.value

	const authSession = await sessionStorage.getSession(request.headers.get('cookie'))
	authSession.set(sessionKey, response.sessionId)
	authSession.set(sessionAuthProviderKey, 'aws')

	return redirect(safeRedirect(response.isOnboarded ? '/returns' : '/onboarding'), {
		headers: {
			'set-cookie': await sessionStorage.commitSession(authSession, {
				expires: response.expireAt,
			}),
		},
	})
}

export async function loader({ request }: LoaderFunctionArgs) {
	await requireAnonymous(request)
	return json({})
}

export default function LoginPage() {
	const actionData = useActionData<typeof action>()
	const navigate = useNavigate()
	const isPending = useIsPending()
	const location = useLocation()
	const [searchParams] = useSearchParams()
	const redirectTo = searchParams.get('redirectTo')
	const authState = searchParams.get('state')
	const [showPassword, setShowPassword] = useState(false)

	const togglePasswordVisibility = () => {
		setShowPassword(prev => !prev)
	}

	const agentFriendlyParam = searchParams.get('friendlyId')
	useEffect(() => {
		const agent_friendly_id = window.localStorage.getItem('agent_friendly_id')
		if (_.isEmpty(agent_friendly_id)) {
			window.localStorage.setItem('agent_friendly_id', agentFriendlyParam ?? '')
		}
	}, [agentFriendlyParam])

	const [form, fields] = useForm({
		id: 'login-form',
		constraint: getZodConstraint(LoginFormSchema),
		defaultValue: { redirectTo },
		lastResult: actionData?.result,
		onValidate({ formData }) {
			return parseWithZod(formData, { schema: LoginFormSchema })
		},
		shouldRevalidate: 'onBlur',
	})

	return (
		<div className="flex min-h-screen flex-col md:flex-row">

			{/* Left: Marketing Section */}
			<div className="dark-background order-last flex w-full justify-center p-6 text-black md:order-none md:w-1/2">
				<div className="flex flex-col items-center justify-center my-5">
					<div>
						<img className="block h-12 w-auto" src="/img/logo/refundr-white.png" alt="refundr" />
					</div>
					<div className="pt-4">
						<img className="block w-auto" src="/img/tax-return-card-sm.png" alt="refundr" />
					</div>
				</div>
			</div>

			{/* Right: Login Section */}
			<div className="flex w-full items-center justify-center bg-white p-6 md:w-1/2">
				<div className="mt-5 w-full max-w-md bg-white">
					<Notification
						key={location.key}
						issue={'unauthorized' === authState ? 'unauthorized' : ''}
						onClickSuccess={() => navigate(location.pathname)}
					/>
					{/* Social Login Buttons */}
					<div className="flex flex-col">
						<button
							className="mb-5 flex h-14 items-center justify-center rounded-lg border border-gray-300 bg-white px-4 text-black shadow-md hover:bg-gray-200"
							onClick={() => navigate('/login-google')}
						>
							<img className="mr-2 h-6 w-6" src="/img/google-icon.svg" alt="google" />
							Login with Google
						</button>
						{/*<button className="mb-5 flex h-14 items-center justify-center rounded-lg border border-gray-100 bg-white px-4 text-black shadow-md hover:bg-gray-100">*/}
						{/*	<img className="mr-2 h-6 w-6" src="/img/facebook-icon.svg" alt="google" />*/}
						{/*	Login with Facebook*/}
						{/*</button>*/}
					</div>
					<div className="flex items-center justify-center gap-2 text-center text-gray-400 text-xs">
						Note: If you use different email addresses for social login and the custom login option below, you’ll end up with two separate refundr accounts.
					</div>
					<div className="my-4 flex items-center justify-center">
						<div className="flex-grow border-t border-gray-300"></div>
						<span className="mx-4 text-gray-500">or</span>
						<div className="flex-grow border-t border-gray-300"></div>
					</div>
					<Form method="POST" {...getFormProps(form)}>
						<ErrorBulletList errors={form.errors} id={form.errorId} />
						<AuthenticityTokenInput />
						<HoneypotInputs />
						<div>
							<Field
								labelProps={{ children: 'email', className: 'text-gray-700' }}
								inputProps={{
									...getInputProps(fields.username, { type: 'email' }),
									autoFocus: true,
									autoComplete: 'username',
									className: 'lowercase h-14 w-full rounded-lg bg-gray-100 px-3 py-2 text-black shadow border-gray-300',
									placeholder: 'Enter your email',
								}}
								errors={fields.username.errors}
							/>
						</div>
						<div>
							<PasswordField
								className="w-full"
								showPassword={showPassword}
								togglePasswordVisibility={togglePasswordVisibility}
								inputProps={{
									...getInputProps(fields.password, { type: 'password' }),
									autoComplete: 'current-password',
									placeholder: 'Enter your password',
								}}
								errors={fields.password.errors}
							/>
						</div>
						<div className="mb-6 flex items-center justify-end">
							<div className="text-sm text-black">
								<Link to="/forgot-password" className="text-body-xs font-semibold">
									forgot password?
								</Link>
							</div>
						</div>
						<div className="mb-4 text-center">
							<input {...getInputProps(fields.redirectTo, { type: 'hidden' })} />
							<StatusButton
								className="rounded h-12 w-full rounded-md bg-blue-500 px-4 py-2 text-white hover:bg-blue-600"
								status={isPending ? 'pending' : form.status ?? 'idle'}
								type="submit"
								disabled={isPending}
							>
								Login
							</StatusButton>
						</div>
						<div className="flex items-center justify-center gap-2 pt-4 font-bold">
							<div className="inline-block cursor-pointer rounded-lg bg-gray-200 px-4 py-2 text-gray-700 hover:bg-gray-300">
								<Link to={redirectTo ? `/signup?${encodeURIComponent(redirectTo)}` : '/signup'} className="text-black">
									Sign up
								</Link>
							</div>
						</div>
					</Form>
				</div>
			</div>
		</div>
	)
}
